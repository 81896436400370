import React from "react";
import { TextField, MenuItem, FormControl as MuiFormControl } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";

const FormControlSpacing = styled(MuiFormControl)(spacing);
const FormControl = styled(FormControlSpacing)`
  width: ${({ customWidth }) => customWidth || "148px"};
  min-width: ${({ customMinWidth }) => customMinWidth || "148px"};
`;

const SelectWrapper = ({ name, options, onChange, my = 2, formControlCustomWidth, formControlCustomMinWidth, ...otherProps }) => {
  const { setFieldValue, values } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (event) => {
    const { value } = event.target;
    setFieldValue(name, value);
    if (onChange) {
      onChange(event);
    }
  };

  const configSelect = {
    ...field,
    ...otherProps,
    select: true,
    variant: "outlined",
    onChange: handleChange,
    fullWidth: true,
    value: values[name],
  };

  return (
    <FormControl my={my} customWidth={formControlCustomWidth} customMinWidth={formControlCustomMinWidth}>
      <TextField {...configSelect}>
        {options.map(({ key, value }) => (
          <MenuItem key={key} value={key}>
            {value}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
};

export default SelectWrapper;
